// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ErrorPage.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ErrorPage.tsx");
}
// REMIX HMR END

import styles from './ErrorPage.module.css';
import { Heading } from './Heading';
import { Text } from './Text';
import { Button } from './Button';
import { usePrefixedTranslation } from '~/hooks/usePrefixedTranslation';
import { SUPPORT_URL } from '../../constants';
export function ErrorPage({
  title,
  message,
  showSupportContact,
  children
}) {
  _s();
  const {
    t
  } = usePrefixedTranslation('error');
  return <main className={styles.page}>
      <div className={styles.body}>
        <img src="/img/whoops.svg" alt="" />

        <Heading level={1} className={styles.heading}>
          {title}
        </Heading>

        {message ? <Text as="p" size="lg">
            {message}
          </Text> : null}

        {showSupportContact ? <Button as="a" to={SUPPORT_URL}>
            {t('contactASupportHero')}
          </Button> : null}

        {children}
      </div>
    </main>;
}
_s(ErrorPage, "YbpGSBOvx+UXrbc9uATeO7+5K1M=", false, function () {
  return [usePrefixedTranslation];
});
_c = ErrorPage;
var _c;
$RefreshReg$(_c, "ErrorPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;